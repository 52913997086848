<template>
	<div class="app-container">
		<el-card class="elCard">
			<div>
				<i class="el-icon-search"></i>
				<span>查询条件</span>
				<el-button style="float: right; margin-left: 5px;" @click="resetForm('searchForm')" size="small">清空</el-button>
				<el-button style="float: right" @click="searchTable()" type="primary" size="small" icon="el-icon-search">查询</el-button>
			</div>
			<div style="margin-top: 15px">
				<el-form :inline="true" ref="searchForm" :model="searchForm" size="small" label-width="100px">
					<el-form-item label="微信昵称：" prop="niceName">
						<el-input v-model="searchForm.niceName" placeholder="请输入微信昵称"></el-input>
					</el-form-item>
					<el-form-item label="openId：" prop="openId">
						<el-input v-model="searchForm.openId" placeholder="请输入openId"></el-input>
					</el-form-item>
					<el-form-item label="区域：" prop="area">
						<el-input v-model="searchForm.area" placeholder="请输入区域"></el-input>
					</el-form-item>
					<el-form-item label="是否启用：" prop="status">
						<el-select v-model="searchForm.status" clearable placeholder="请选择" autocomplete="off" style="max-width: 200px;">
						    <el-option v-for="item in statusArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
				</el-form>
			</div>
		</el-card>
		<el-row>
			<el-button type="primary" size="medium" icon="el-icon-s-promotion" @click="sendBtn()">发送消息</el-button>
			<el-popover placement="bottom" width="150" v-model="visible" style="float: right;">
				<el-checkbox-group v-model="checkList" @change="checkedChange">
				    <el-checkbox v-for="item in checkArr" :label="item.val">{{item.label}}</el-checkbox>
				</el-checkbox-group>
				<el-button type="primary" size="medium" icon="el-icon-s-grid" circle slot="reference"></el-button>
			</el-popover>
			<el-button style="float: right; margin-right: 5px;" size="medium" type="primary" icon="el-icon-refresh-left" circle @click="page = 1;getList()"></el-button>
		</el-row>
		<el-card>
			<el-table :row-key="row => row.id" :data="tableData" :stripe="true" border v-loading="listLoading" ref="subjecttable" :default-sort = "{prop: 'integral', order: 'descending'}" style="width: 100%" @selection-change="handleSelectionChange">
				<el-table-column :reserve-selection="true" type="selection" width="55"></el-table-column>
				<el-table-column v-for="item in checkArr" v-if="item.dis" :prop="item.val" :sortable="item.val == 'integral' ? true : false" :label="item.label" min-width="110">
					<template slot-scope="scope">
						<span v-if="item.val == 'headImg'"><el-avatar size="medium" :src="scope.row.headImg"></el-avatar></span>
						<span v-else-if="item.val == 'status'">{{scope.row.status == 0 ? '禁用' : '启用'}}</span>
						<span v-else-if="item.val == 'online'" :class="scope.row.online ? 'stex' : 'ftex'">{{scope.row.online ? '在线' : '不在线'}}</span>
						<span v-else>{{scope.row[item.val]}}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="210" fixed="right">
					<template slot-scope="scope">
						<el-button class="marginRight10" type="primary" size="mini" icon="el-icon-edit" @click="gameClick(scope.row)">积分明细</el-button>
						<el-button class="marginLeft0 marginRight10 marginTop5" type="danger" size="mini" icon="el-icon-tickets" @click="addCode(scope.row)">添加积分</el-button>
						<el-button class="marginLeft0 marginTop5" type="success" size="mini" icon="el-icon-message" @click="sendMeg(scope.row)">发送消息</el-button>
					</template>
				</el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange" :current-page="page" layout="sizes, total, prev, pager, next, jumper" :total="total"></el-pagination>
		</el-card>
		<el-dialog title="积分明细" :visible.sync="dialogFormVisibles" :append-to-body="true">
			<el-card style="margin-bottom: 20px;">
				<div>
					<i class="el-icon-search"></i>
					<span>查询条件</span>
					<el-button style="float: right; margin-left: 5px;" @click="resetFormS('searchFormS')" size="small">清空</el-button>
					<el-button style="float: right" @click="searchTableS()" type="primary" size="small" icon="el-icon-search">查询</el-button>
				</div>
				<div style="margin-top: 15px">
					<el-form :inline="true" ref="searchFormS" :model="searchFormS" size="small" label-width="100px">
						<el-form-item label="获取途径：" prop="type">
							<!-- <el-input v-model="searchFormS.type" placeholder="请输入获取途径"></el-input> -->
							<el-select v-model="searchFormS.type" clearable placeholder="请选择" autocomplete="off" style="max-width: 200px;">
							    <el-option v-for="item in typeArr" :key="item.value" :label="item.label" :value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-form>
				</div>
			</el-card>
			<el-table :data="tableDataS" :stripe="true" border v-loading="listLoadingS" style="width: 100%">
				<el-table-column prop="createdTxStamp" label="时间"></el-table-column>
				<el-table-column prop="type" label="获取途径"></el-table-column>
				<el-table-column prop="integralNum" label="积分"></el-table-column>
			</el-table>
			<!-- 分页 -->
			<el-pagination @current-change="handleCurrentChangeS" :current-page="pageS" layout="total, prev, pager, next, jumper" :total="totalS"></el-pagination>
		</el-dialog>
		<el-dialog title="发送消息" :visible.sync="dialogSendVisibles" :append-to-body="true">
			<el-form :model="sendForm" :rules="sendRules" ref="sendForm" label-width="150px">
				<el-form-item label="活动主题：" prop="title">
					<el-input v-model="sendForm.title" placeholder="请输入活动主题"></el-input>
				</el-form-item>
				<el-form-item label="赛事地点：" prop="address">
					<el-input v-model="sendForm.address" placeholder="请输入赛事地点"></el-input>
				</el-form-item>
				<el-form-item label="开始时间：" prop="startTime">
					<el-date-picker v-model="sendForm.startTime" type="datetime" placeholder="选择开始时间"></el-date-picker>
				</el-form-item>
				<el-form-item label="温馨提醒：" prop="tips">
					<el-input v-model="sendForm.tips" placeholder="请输入温馨提醒(20个字内)" maxlength="20"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogSendVisibles = false">取 消</el-button>
				<el-button type="primary" v-loading.fullscreen.lock="fullscreenLoadingA" @click="dialogConfirm('sendForm')">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	let timeL = '', qtime = true;
	export default {
		data() {
			return {
				openId: '',
				fullscreenLoadingA: false,
				sendForm: {
					title: '',
					address: '',
					startTime: '',
					tips: ''
				},
				sendRules: {
					title: [{ required: true, message: '请输入活动主题', trigger: 'blur' }],
					address: [{ required: true, message: '请输入赛事地点', trigger: 'blur' }],
					startTime: [{ required: true, message: '请选择开始时间', trigger: 'blur' }],
					tips: [{ required: true, message: '请输入温馨提醒', trigger: 'blur' }]
				},
				page: 1,
				pageSize: 10,
				pageS: 1,
				searchForm: {
					niceName: '',
					openId: '',
					area: '',
					status: 1
				},//条件查询form
				searchFormS: {
					type: ''
				},//获取途径查询
				statusArr: [{
					value: 0,
					label: "禁用"
				}, {
					value: 1,
					label: "启用"
				}],//是否启用
				typeArr: [],//积分明细-获取途径
				tableData: [],//表格
				tableDataS: [],//积分明细表格
				multipleSelection: [],//表格选中数组
				total: 0,//分页总条数
				totalS: 0,
				visible: false,
				checkArr: [
					{label: "微信头像", val: "headImg", dis: true},
					{label: "微信昵称", val: "niceName", dis: true},
					{label: "手机号", val: "phone", dis: true},
					{label: "openId", val: "openId", dis: true},
					{label: "区域", val: "area", dis: true},
					{label: "积分总数", val: "integral", dis: true},
					{label: "是否在线", val: "online", dis: true},
					{label: "是否启用", val: "status", dis: true}
				],
				checkList: ["headImg", "niceName", "phone", "openId", "area", "integral", "online", "status"],
				dialogFormVisibles: false,//积分明细弹窗
				dialogSendVisibles: false,//发送消息弹窗
				listLoading: false,
				listLoadingS: false,
				wxUserId: ''
			}
		},
		components: {
			
		},
		created(){
			this.getList(2);
		},
		methods:{
			getList(type) {
				if(type != 1) {
					this.listLoading = true;
				}
				// 查询内容转数组
				// let obj = this.searchForm;
				// let arr = Object.keys(obj);
				// let arrs = [];
				// arr.forEach(item => {
				// 	let o = {};
				// 	o.col = item;
				// 	o.val = obj[item];
				// 	if(item == "niceName") {
				// 		o.type = "like";
				// 	} else {
				// 		o.type = "=";
				// 	}
				// 	arrs.push(o);
				// })
				
				// let data = {
				// 	entity: 'FaExamUser',
				// 	page: this.page,
				// 	pageSize: 10,
				// 	filter: JSON.stringify(arrs),
				// 	// sidx: 'sort',
				// 	sord: 'desc'
				// }
				let data = {
					page: this.page,
					pageSize: this.pageSize,
					niceName: this.searchForm.niceName,
					openId: this.searchForm.openId,
					area: this.searchForm.area,
					status: this.searchForm.status
				}
				this.$comjs.ajax.getAjax('/fa/user/query', data, this, res => {
					this.tableData = res.list;
					this.total = res.total;
					this.listLoading = false;
					if(type) {
						timeL = setTimeout(() => {
							if(qtime) {
								this.getList(1);
							}
						}, 3000)
					}
				});
			},
			getListS() {
				this.listLoadingS = true;
				let data = {
					wxUserId: this.wxUserId,
					pageNumber: this.pageS,
					type: this.searchFormS.type,
					pageSize: 10
				}
				this.$comjs.ajax.getAjax('/fa/user/integral-detail', data, this, res => {
					this.tableDataS = res.list;
					this.totalS = res.total;
					this.listLoadingS = false;
				});
			},
			// 搜索
			searchTable() {
				this.page = 1;
				this.getList();
			},
			searchTableS() {
				this.pageS = 1;
				this.getListS();
			},
			// 重置
			resetForm(formName) {
				this.$refs[formName].resetFields();
				this.page = 1;
				this.getList();
			},
			resetFormS(formName) {
				this.$refs[formName].resetFields();
				this.pageS = 1;
				this.getListS();
			},
			// 群发
			sendBtn() {
				// console.log(this.multipleSelection)
				if(this.multipleSelection.length > 0) {
					this.sendMeg();
				} else {
					this.$message({
						message: '请选择用户！',
						type: 'warning',
						duration: 1000
					});
				}
			},
			// 表格选中
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			// 分页
			handleCurrentChange(val) {
				this.page = val;
				this.getList();
			},
			handleCurrentChangeS(val) {
				this.pageS = val;
				this.getListS();
			},
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
				// console.log(`每页 ${val} 条`);
			},
			// 多选框
			checkedChange(value) {
				let arr = this.checkArr;
				arr.forEach(item => {
					item.dis = false;
				})
				value.forEach(item => {
					let index = arr.findIndex(d => d.val === item);
					arr[index].dis = true;
				})
				this.checkArr = arr;
			},
			// 积分明细
			gameClick(row) {
				this.wxUserId = row.wxUserId;
				this.pageS = 1;
				this.dialogFormVisibles = true;
				
				this.$comjs.ajax.getAjax('/fa/exam/user/integral/record/integral-type', {}, this, res => {
					this.typeArr = res.list;
				});
				this.getListS();
			},
			// 添加积分
			addCode(row) {
				this.$prompt('请输入积分，不超过四位数', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					inputType: 'number',
					inputPattern: /^\d{1,4}$/,
					inputErrorMessage: '积分格式不正确'
				}).then(({ value }) => {
					this.wxUserId = row.wxUserId;
					// console.log(value)
					let data = {
						wxUserId: this.wxUserId,
						integral: value
					}
					this.$comjs.ajax.postAjax('/fa/user/add-integral', data, this, res => {
						this.$message({
							message: res.message,
							type: 'success',
							duration: 1500
						});
					});
				}).catch(() => {
					// console.log("取消")
				});
			},
			// 发送消息
			sendMeg(row) {
				this.openId = [];
				if(row) {
					this.openId.push(row.openId);
				} else {
					this.multipleSelection.forEach(item => {
						this.openId.push(item.openId);
					})
				}
				console.log(this.openId)
				this.dialogSendVisibles = true;
			},
			dialogConfirm(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						let data = {
							openidList: this.openId,
							title: this.sendForm.title,
							address: this.sendForm.address,
							startTime: this.sendForm.startTime,
							tips: this.sendForm.tips
						}
						
						this.$confirm('是否发送消息', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							this.fullscreenLoadingA = true;
							this.$comjs.ajax.postAjax('/fa/user/send-template', data, this, res => {
								this.fullscreenLoadingA = false;
								this.dialogSendVisibles = false;
								this.$refs[formName].resetFields();
								// this.$message({
								// 	message: res.message,
								// 	type: 'success',
								// 	duration: 1000
								// });
								this.$alert(res.message, '提示', {
									customClass: 'alertPre',
									dangerouslyUseHTMLString: true
								});
							}, res => {
								this.fullscreenLoadingA = false;
								this.$message({
									message: res.message,
									type: 'warning',
									duration: 1000
								});
							});
						}).catch(() => {
							
						});
					}
				});
			},
		},
		computed:{
			
		},
		watch:{
		    "$route":{
				handler(route){
					// const that=this;
					// console.log(route.name)
					if(route.name == 'player'){
						qtime = true;
						this.getList(1);
					} else {
						clearTimeout(timeL);
						qtime = false;
					}
				}
		    }
		},
		destroyed () {
			// 销毁监听
			clearTimeout(timeL);
			qtime = false;
		}
	}
</script>

<style>
	.alertPre {
		width: 500px;
		white-space: pre-wrap;
	}
</style>
<style lang="scss" scoped>
	.app-container {
		padding: 20px;
	}
	.elCard {
		margin-top: 20px;
	}
	.el-row {
		padding: 20px 0;
	}
	.el-pagination {
		margin-top: 15px;
		text-align: left;
	}
	.stex {
		color: #13CE66;
	}
	.ftex {
		color: #f55;
	}
</style>

